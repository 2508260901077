import React from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import "../styles/css/SocialsContainer.css";

export default class SocialsContainer extends React.Component {
  render(): JSX.Element {
    return (
      <Container className="socialsContainer">
        <div className="socialsHeader">STAY CONNECTED</div>
        <div>
          <ul>
            <li>
              <a href="https://github.com/joshuayuen99" target="_blank" rel="noreferrer">
                <img src="/github.png" alt="GitHub Link"></img>
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/in/joshuayuen99" target="_blank" rel="noreferrer">
                <img src="/linkedin.png" alt="LinkedIn Link"></img>
              </a>
            </li>
            <li>
              <a href="https://discord.gg/YFgbrfB" target="_blank" rel="noreferrer">
                <img src="/discord_transparent.png" alt="Discord Link"></img>
              </a>
            </li>
            <li>
              <Link to="/contact">
                <img src="/email.png" alt="Contact Link"></img>
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    );
  }
}
