import React from "react";
import Alert from "react-bootstrap/Alert";

export type AlertVariantType = "success" | "danger";

export type DismissableAlertProps = {
  variant: AlertVariantType;
  show: boolean;
  onClose: () => void;
};

export default class DismissableAlert extends React.Component<
  DismissableAlertProps
> {
  render(): JSX.Element {
    return (
      <Alert
        dismissible
        variant={this.props.variant}
        show={this.props.show}
        onClose={this.props.onClose}
      >{this.props.children}</Alert>
    );
  }
}
