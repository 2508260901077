import React from "react";
import Container from "react-bootstrap/Container";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { About, Contact, Footer, Home, Projects } from "./components";
import Navbar from "./components/Navbar";
import NotFound from "./components/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import "./styles/css/App.css";

export default class App extends React.Component {
  render(): JSX.Element {
    return (
      <div className="App">
        <Router>
          <Navbar />
          <Container fluid className="d-flex flex-column g-0">
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/projects" component={() => <Projects />} />
              <Route exact path="/contact" component={Contact} />
              <Route path="*" component={NotFound}/>
            </Switch>
          </Container>
          <Footer />
        </Router>
      </div>
    );
  }
}
