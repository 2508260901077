import React from "react";
import { History } from "history";
import { withRouter } from "react-router-dom";

type params = {
  history: History;
}

function ScrollToTop({ history }: params) {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);