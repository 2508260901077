import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Plausible from "plausible-tracker";
const { trackEvent } = Plausible( { apiHost: "https://plausible.joshuayuen.me"} );

export default class NotFound extends React.Component {
  render(): JSX.Element {
    trackEvent("404");

    return (
      <Row className="notFound">
        <Col><h1>404 Page not found!</h1></Col>
      </Row>
    );
  }
}
