/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "../styles/css/Home.css";

export default class Home extends React.Component {
  render(): JSX.Element {
    return (
      <Container fluid id="home" className="mainContent">
        <Row className="landingDiv">
          <Col className="d-flex">
            <Container>
              <h1>{"JOSHUA YUEN"}</h1>
              <h4>
                {
                  "Self-learning software to solve problems I've made for myself."
                }
              </h4>
            </Container>
          </Col>
        </Row>
        <Row className="myWorkDiv">
          <Col>
            <Container>
              <h3>MY WORK</h3>
              <p>
                  I've always been interested in coming up with creative solutions to problems. A lot of the time these "problems" come from video games that I'm interested in (see <a href="/projects#TundraHooks">TundraHooks</a>, <a href="/projects#GTA_Horse_Racing_Bot">GTA Horse Racing Bot</a>, or <a href="/projects#Auto_Lamp">Auto Lamp</a>). Other times, my problems are more practical and have real value to being solved (see <a href="/projects#SoundCloud_to_Spotify_Playlist_Converter">SoundCloud to Spotify Playlist Converter</a> or <a href="/projects#Samsung_Watch_WoL_App">Samsung Watch WoL App</a>).
                  
              </p>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}
