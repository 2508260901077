import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SkillsList, { SkillInfo } from "./SkillsList";

export default class SkillsListContainer extends React.Component {
  static skills: SkillInfo[] = [
    {
      category: "Programming",
      skills: [
        "TypeScript/JS",
        "C/C++",
        "Python",
        "Java",
        "Bash",
        "x86 Assembly",
      ],
    },
    {
      category: "Web Technologies",
      skills: [
        "Node.js",
        "SCSS/CSS",
        "React",
        "Bootstrap",
        "Pug",
        "Express",
        "OAuth2",
        "HTML",
      ],
    },
    {
      category: "Containerization & Virtualization",
      skills: ["Docker", "Proxmox", "VirtualBox"],
    },
    {
      category: "Databases",
      skills: ["PostgreSQL", "MongoDB"],
    },
    {
      category: "Cloud Infrastructure",
      skills: ["AWS EC2", "DigitalOcean", "Amazon S3 Glacier", "DNS Management", "Apache", "Heroku"],
    },
    {
      category: "Tools & Software",
      skills: ["VS Code", "Visual Studio", "Jira", "Dovecot", "Postfix"],
    },
    {
      category: "Version Control",
      skills: ["Git", "GitHub"],
    },
    {
      category: "Systems",
      skills: ["Linux (Ubuntu, Arch, Debian, Raspian)", "Windows/WSL", "BTRFS", "ZFS"],
    },
  ];

  static familiarSkills: SkillInfo[] = [
    {
      category: "Programming",
      skills: ["Rust", "Go", "PHP"],
    },
    {
      category: "Containerization & Virtualization",
      skills: ["Kubernetes"],
    }
  ];

  render(): JSX.Element {
    const skillsLists = SkillsListContainer.skills.map((skillInfo) => {
      return (
        <SkillsList
          key={skillInfo.category}
          category={skillInfo.category}
          skills={skillInfo.skills}
        />
      );
    });

    const experiencesLists = SkillsListContainer.familiarSkills.map((skillInfo) => {
      return (
        <SkillsList
          key={skillInfo.category}
          category={skillInfo.category}
          skills={skillInfo.skills}
        />
      );
    });

    return (
      <Container fluid id="skillsListContainer">
        <div className="subTitle">
          <h3>TECHNICAL SKILLS</h3>
        </div>
        <Row className="justify-content-center">{skillsLists}</Row>
        <div className="subTitle">
          <h3>FAMILIAR WITH</h3>
        </div>
        <Row className="justify-content-center">{experiencesLists}</Row>
      </Container>
    );
  }
}
