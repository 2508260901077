import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import htmlReactParser from "html-react-parser";

export type ProjectCardInfo = {
  title: string;
  bodyText: string;
  imageSource: string;
  buttonText: string;
  buttonInternalRoute?: string;
  buttonExternalLink?: string;
};

export default class ProjectCard extends React.Component<ProjectCardInfo> {
  render(): JSX.Element {
    return (
      <Card style={{ width: "24rem" }}>
        <a id={this.props.title.replace(/ /gm, "_")}></a>
        <Card.Img variant="top" src={this.props.imageSource} />
        <Card.Body className="d-flex flex-column">
          <Card.Title as="h4">{this.props.title}</Card.Title>
          <Card.Text>{htmlReactParser(this.props.bodyText)}</Card.Text>
          {this.props.buttonExternalLink ? (
            <Button
              variant="primary"
              className="mt-auto ms-auto"
              href={this.props.buttonExternalLink}
            >
              {this.props.buttonText}
            </Button>
          ) : (
            <Link className="btn btn-primary mt-auto ms-auto" to={this.props.buttonInternalRoute ?? "/"}>{this.props.buttonText}</Link>
          )}
        </Card.Body>
      </Card>
    );
  }
}
