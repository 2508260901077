import React from "react";
import "../styles/css/Footer.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { SocialsContainer } from ".";

export default class Footer extends React.Component {
  render(): JSX.Element {
    return (
      <Container fluid id="footer">
        <Row id="footerSocials">
          <SocialsContainer />
        </Row>
        <Row id="footerBottom">
          <Container>
            <Row>
              <Col className="col-6 col-md">
                <Link to="/">Home</Link>
              </Col>
              <Col className="d-none d-md-block lineBreak">
                <p>|</p>
              </Col>
              <Col className="col-6 col-md">
                <Link to="/about">About</Link>
              </Col>
              <Col className="d-none d-md-block lineBreak">
                <p>|</p>
              </Col>
              <Col className="col-6 col-md">
                <Link to="/projects">Projects</Link>
              </Col>
              <Col className="d-none d-md-block lineBreak">
                <p>|</p>
              </Col>
              <Col className="col-6 col-md">
                <Link to="/contact">Contact</Link>
              </Col>
            </Row>
            <Row id="copyright">
              <span>© Joshua Yuen {new Date().getFullYear()}. All rights reserved.</span>
            </Row>
          </Container>
        </Row>
      </Container>
    );
  }
}
