/* eslint-disable react/no-unescaped-entities */
import axios, { AxiosResponse } from "axios";
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import DismissibleAlert, { AlertVariantType } from "./DismissibleAlert";

export type FormStateInfo = {
  validated: boolean;
  isSending: boolean;

  showAlert: boolean;
  alertVariant: AlertVariantType;
  alertText: JSX.Element | string;
  
  name: string;
  email: string;
  subject: string;
  message: string;
};

export default class ContactForm extends React.Component<Record<string, never>, FormStateInfo> {
  constructor(props: Record<string, never>) {
    super(props);

    this.state = {
      validated: false,
      isSending: false,

      showAlert: false,
      alertVariant: "success",
      alertText: "Email sent!",

      name: "",
      email: "",
      subject: "",
      message: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render(): JSX.Element {
    return (
      <Form
        className="emailForm"
        validated={this.state.validated}
        noValidate
        onSubmit={this.handleSubmit}
      >
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            name="formName"
            className="shadow-none"
            value={this.state.name}
            required
            onChange={this.handleChange}
            type="string"
            placeholder="John Smith"
          />
          <Form.Control.Feedback>Hi {this.state.name}!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Let me know your name!
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="formEmail"
            className="shadow-none"
            value={this.state.email}
            required
            onChange={this.handleChange}
            type="email"
            placeholder="name@example.com"
          />
          <Form.Control.Feedback>
            I'll get back to you at {this.state.email}!
          </Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please enter your email.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            name="formSubject"
            className="shadow-none"
            value={this.state.subject}
            required
            onChange={this.handleChange}
            type="string"
            placeholder="Love the website!"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Please enter the subject.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            name="formMessage"
            className="shadow-none"
            as="textarea"
            value={this.state.message}
            required
            onChange={this.handleChange}
            rows={5}
            placeholder="Hey Josh!"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            Enter a message!
          </Form.Control.Feedback>
        </Form.Group>

        <div className="alertDiv">
          <DismissibleAlert variant={this.state.alertVariant} show={this.state.showAlert} onClose={() => this.setState({ showAlert: false })}>
            {this.state.alertText}
          </DismissibleAlert>
        </div>

        <div className="buttonDiv d-grid">
          <Button
            className="shadow-none"
            variant="primary"
            type="submit"
            disabled={this.state.isSending}
          >
            {this.state.isSending ? "Sending..." : "Send!"}
          </Button>
        </div>
      </Form>
    );
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    switch (event.target.name) {
      case "formName":
        this.setState({ name: event.target.value });
        break;
      case "formEmail":
        this.setState({ email: event.target.value });
        break;
      case "formSubject":
        this.setState({ subject: event.target.value });
        break;
      case "formMessage":
        this.setState({ message: event.target.value });
        break;
      default:
    }
  }

  handleSubmit(event: React.FormEvent): void {
    type axiosResponseType = {
      msg: string;
    };

    type axiosRequestType = {
      name: string;
      email: string;
      subject: string;
      messageHtml: string;
    }

    event.preventDefault();

    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validated: true });

      return;
    }

    // Disable send button
    this.setState({ isSending: true });

    axios
      .post<axiosRequestType, AxiosResponse<axiosResponseType>>("/api/send-email", {
        name: this.state.name,
        email: this.state.email,
        subject: this.state.subject,
        messageHtml: this.state.message,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          this.displayEmailSentStatus(true);

          // Reset subject and message fields
          this.resetForm();
        } else if (response.data.msg === "fail") {
          this.displayEmailSentStatus(false);
        } else {
          this.displayEmailSentStatus(false);
        }
      })
      .catch((err) => {
        // Backend server probably not running
        this.displayEmailSentStatus(false);
        console.error(err);
      })
      .finally(() => {
        // Re-enable send button
        this.setState({ isSending: false });
      });
  }

  displayEmailSentStatus(success: boolean): void {
    this.setState({ showAlert: true });

    if (success) {
      this.setState({ alertVariant: "success", alertText: "Email sent!" });
    } else {
      this.setState({ alertVariant: "danger", alertText: "There was an internal error sending the email, sorry for the inconvenience. I'd still love to hear from you, so please send me an email the old-fashioned way!" });
    }
  }

  resetForm(): void {
    this.setState({ subject: "", message: "", validated: false });
  }
}
