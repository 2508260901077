import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../styles/css/Navbar.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";

export default class NavbarComponent extends React.Component {
  render(): JSX.Element {
    return (
      <Navbar id="navbar" expand="sm" sticky="top">
        <Container fluid>
          <Navbar.Brand as={NavLink} to="/">
            Joshua Yuen
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={location.pathname} className="me-auto">
              <Nav.Link as={NavLink} to="/about">
                About
              </Nav.Link>
              <Nav.Link as={NavLink} to="/projects">
                Projects
              </Nav.Link>
              <Nav.Link as={NavLink} to="/contact">
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }
}
