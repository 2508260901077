import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProjectCard, { ProjectCardInfo } from "./ProjectCard";

export default class ProjectCardsContainer extends React.Component {
  static projects: ProjectCardInfo[] = [
    {
      title: "TundraBot",
      bodyText:
        "TundraBot has been a pet project of mine since November 2019. My motivation for creating it was to make something that I could see myself frequently using, and to gain more experience with event-driven programming. I consider it to be an all-in-one Discord bot, with commands for music, moderation, utilities/convenience, games, and more. Currently deployed with AWS.",
      imageSource: "/discord_banner.png",
      buttonText: "Visit homepage",
      buttonExternalLink: "https://tundrabot.xyz",
    },
    {
      title: "Personal Website",
      bodyText:
        "This website you're currently looking at! Written in React + SCSS + Bootstrap. Currently being served with apache on a Digital Ocean droplet.",
      imageSource: "/logo512.png",
      buttonText: "Visit homepage",
      buttonExternalLink: "https://joshuayuen.me",
    },
    {
      title: "TundraCraft",
      bodyText: "A Minecraft utility client for versions 1.18-1.19. It includes over 20 modules, with notable ones being kill-aura, built-in custom Discord integration, blink, and velocity. I gained experience with packet manipulation and Java mixins. Written in Java and utilizes the Fabric toolchain.",
      imageSource: "/fabric.png",
      buttonText: "Project Details",
      buttonInternalRoute: "/",
    },
    {
      title: "TundraHooks",
      bodyText:
        "An internal cheat for CS:GO. Main features include aimbot, ESP, player/weapon chams, recoil crosshair, radar, glow, auto-bhop, no-flash, and more. Written in C++.",
      imageSource: "/logo512.png",
      buttonText: "Project Details",
      buttonInternalRoute: "/",
    },
    {
      title: "GTA Horse Racing Bot",
      bodyText:
        "A bot designed for GTA V Online that automatically bets on horse races at the casino. It's capable of betting on both the main event, as well as the single event, so as to maximize profits. It utilizes <a href=\"https://opensource.google/projects/tesseract\"> Google Tesseract </a> to optimize bets through optical character recognition (OCR).",
      imageSource: "/gta_horse_racing_bot.png",
      buttonText: "Project Details",
      buttonInternalRoute: "/",
    },
    {
      title: "DMV Appointment Bot",
      bodyText:
        "A bot capable of monitoring the DMV website for appointment openings for a given appointment type (driver's license test, learner's permit test, etc.) on specified days, at specified times, and automatically booking an appointment for you. (Proof of concept).",
      imageSource: "/calendar.jpg",
      buttonText: "Project Details",
      buttonInternalRoute: "/",
    },
    {
      title: "SoundCloud to Spotify Playlist Converter",
      bodyText:
        "A utility I created to convert a SoundCloud playlist to a Spotify playlist. With a playlist of 1000+ songs, I surely wasn't going to go through by hand and add each song one-by-one.",
      imageSource: "/soundcloud_spotify.png",
      buttonText: "Project Details",
      buttonInternalRoute: "/",
    },
    {
      title: "Auto Lamp",
      bodyText:
        "Auto Lamp is a Minecraft 1.15 Forge mod that adds a new block to the game that essentially combines the functionality of a daylight sensor directly into a light source. The Auto Lamp block automatically turns on at night, and turns back off during the day, all-in-one. It even includes a crafting recipe! Works for both singleplayer and multiplayer.",
      imageSource: "/redstone_lamp.png",
      buttonText: "Project Details",
      buttonInternalRoute: "/",
    },
    {
      title: "Samsung Watch WoL App",
      bodyText:
        "This app is designed for Samsung watches, and lets the user utilize their computer's Wake-On-LAN feature directly from their wrist. It contains both an app portion, as well as a widget portion for convenience. Features include being able to save a list of multiple computers, and choosing between them when it comes to waking them up.",
      imageSource: "/wake_on_lan.png",
      buttonText: "Project Details",
      buttonInternalRoute: "/",
    },
    {
      title: "Color Blob Detector",
      bodyText:
        "The Blob Detector takes an image and an RGB value to look for. It identifies pixels within a given \"distance\" from the given color and removes anything that falls outside the range.",
      imageSource: "/Circles_Card_Cross_v2.png",
      buttonText: "Check it out!",
      buttonInternalRoute: "/",
    }
  ];

  render(): JSX.Element {
    const projectCards = ProjectCardsContainer.projects.map(
      (project, index) => {
        return (
          <Col
            key={index}
            className="col-12 col-lg-4 col-md-6 d-flex justify-content-center"
          >
            <ProjectCard
              title={project.title}
              bodyText={project.bodyText}
              imageSource={project.imageSource}
              buttonText={project.buttonText}
              buttonInternalRoute={project.buttonInternalRoute}
              buttonExternalLink={project.buttonExternalLink}
            />
          </Col>
        );
      }
    );

    return (
      <Container className="projectCardsContainer">
        <Row>{projectCards}</Row>
      </Container>
    );
  }
}
