/* eslint-disable react/no-unescaped-entities */
import React from "react";
import "../styles/css/Projects.css";
import { ProjectCardsContainer } from ".";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export default class Projects extends React.Component {
  render(): JSX.Element {
    return (
      <Container id="projects" className="mainContent">
        <Row className="header">
          <h1>PROJECTS</h1>
        </Row>
        <Container fluid className="content">
          <Row className="projectsDiv">
            <Col>
              <ProjectCardsContainer />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
