/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "../styles/css/About.css";
import SkillsListContainer from "./SkillsListContainer";

export default class About extends React.Component {
  render(): JSX.Element {
    return (
      <Container fluid id="about" className="mainContent">
        <Row className="header">
          <h1>ABOUT ME</h1>
        </Row>
        <Container fluid className="content">
          <Row className="aboutMeDiv">
            <Col>
              <Container>
                <h2>Current Job</h2>
                <p>
                  I'm currently a Network Protocol Engineer at <a href="https://akayla.com">Akayla</a> doing consulting for the government on 5G O-RAN specifications and implementations under the guidance of Sean Turner and Russ Housley. Half of my time is also spent working with a cybersecurity company performing as a software engineer in an agile development environment developing PKI technology.
                </p>
                <h2>Education and Previous Work</h2>
                <p>
                  I graduated from George Mason University with a bachelor's in Computer Science. While there, I worked as a research assistant in the Measurable Security Lab, where I worked on <a href="https://kurer.daneportal.net/">Kurer</a>, an Outlook add-in that utilizes <a href="https://en.wikipedia.org/wiki/DNS-based_Authentication_of_Named_Entities">DANE</a> for encrypting and signing emails with S/MIME. I also designed and developed a custom batching system in C for large-scale distributed WHOIS requests. 
                </p>
                <h2>Hobbies</h2>
                <p>
                  I enjoy tinkering with anything technology. Recently I've began self-hosting technologies ranging from my own mail server, to a media server (Plex) and password manager (Bitwarden) for family, to game servers for games like Minecraft or CS:GO for friends. I've also gotten into ricing my Arch Linux installation, with my favorite part being that absolutely anything is possible, I just need to write the utilities and configurations to make it reality.
                </p>
                <div className="buttonDiv d-grid">
                  <a target="_blank" rel="noopener noreferrer" href="/resume.pdf">
                    Resume PDF
                  </a>
                </div>
              </Container>
            </Col>
          </Row>
          <Row className="skillsListDiv">
            <Col>
              <SkillsListContainer />
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
