import React from "react";
import Col from "react-bootstrap/Col";

export type SkillInfo = {
  category: string;
  skills: string[];
};

export default class SkillsList extends React.Component<SkillInfo> {
  render(): JSX.Element {
    const listItems = this.props.skills.map((skill, index) => {
      return <li key={index}>{skill}</li>;
    });

    return (
      <Col className="skillsList col-12 col-lg-3 col-md-6">
        <h5>{this.props.category}</h5>
        <ul>{listItems}</ul>
      </Col>
    );
  }
}
