import React from "react";
import "../styles/css/Contact.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContactForm from "./ContactForm";

export default class Contact extends React.Component {
  render(): JSX.Element {
    return (
      <Container fluid id="contact" className="mainContent">
        <Row className="header">
          <h1>CONTACT ME</h1>
        </Row>
        <Container fluid className="content">
          <Row className="contactFormDiv">
            <Col>
              <Container fluid className="contactFormContainer">
                <ContactForm />
              </Container>
            </Col>
          </Row>
          <Row className="contactEmail">
            <Col>
              Or email me at: <a href="mailto:josh@joshuayuen.me"><i>josh@joshuayuen.me</i></a>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
}
